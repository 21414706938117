.screen-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: absolute;
  width: 100vw;

  .screen-loader-ring {
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    animation: screen-loader-ring 2s linear infinite;
    z-index: 9;
  }

  @keyframes screen-loader-ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 5px 2px #e65c00;
    }

    50% {
      transform: rotate(180deg);
      box-shadow: 1px 5px 2px #18b201;
    }

    100% {
      transform: rotate(360deg);
      box-shadow: 1px 5px 2px #0456c8;
    }
  }

  .screen-loader-ring:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  span {
    color: #737373;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out infinite;
  }

  @keyframes text {
    50% {
      color: rgb(223, 223, 223);
    }
  }
}
