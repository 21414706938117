.nav {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;

  .nav-logo,
  .nav-menu,
  .nav-menu-list {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .nav-menu-list {
    justify-content: space-between;
  }
  .nav-menu-item,
  .nav-logo {
    cursor: pointer;
  }

  .nav-menu-item:hover {
    color: #4a74ea;
  }

  .nav-menu {
    justify-content: flex-end;
    gap: 10px;

    p {
      cursor: pointer;
    }
  }
}
.modal-coming-soon {
  .click-here {
    margin-top: 10px;
    width: 100%;
    font-size: 20px;

    span {
      cursor: pointer;
      color: #4a74ea;
      font-size: 22px;
    }
    span:hover {
      text-decoration: underline;
    }
  }
}
